import React, { useEffect, useState, useRef } from "react";

//svg 
import DownArrowSVG from "../SVG/down-arrow-svg"

const AccordianStructure = ({ answer, question, activeTab, index, activateTab, faqIndex }) => {

    const [ispageload, setPageload] = useState(false)
    const [height, setHeight] = useState(0)
    const [heightQ, setHeightQ] = useState(0)
    const isActive = activeTab === index;
    const currentref = useRef(null)

    useEffect(() => {
        updateDimensions();
        window.setTimeout(() => {
            updateDimensions();
        }, 150);
        window.setTimeout(() => {
            setPageload(true)
        }, 333);
        window.addEventListener('resize', updateDimensions);
        return () => { window.removeEventListener('resize', updateDimensions); }
    },[])


    const updateDimensions = () => {
        window.setTimeout(() => {         
            const height1 = document.querySelector('.faq-panel__inner .faq-panel__content').scrollHeight;
            const heightQ1 = document.querySelector('.faq-panel__question p').scrollHeight;
            setHeight(height1)
            setHeightQ(heightQ1)
        }, 200);
    }

  
    return (
        <>
            <div            
                aria-hidden="true"
                className="faq-panel relative mb-2 justify-between border border-tone-ternary"
                aria-expanded={isActive}
                onClick={activateTab}>
                <div className="cursor-pointer absolute faq-arrow-main">
                    <DownArrowSVG />
                </div>
                <div className="faq-panel__question cursor-pointer" style={ispageload ? { height: `${isActive ? 0 : heightQ}px` } : {}}>
                    <p className="pr-60 pl-5 mms:pr-100 mms:pl-50 py-5 lg:py-6-2 lg:pl-20 lg:pr-1-33 text-term-primary que-content-inn font-worksans-normal text-p4 lg:text-p2">{question}</p>
                </div>
                <div className="faq-panel__inner bg-background-secondary" aria-hidden={!isActive} style={{ height: `${isActive ? height : 0}px` }}>
                    <div ref={currentref} className="pr-60 pl-5 mms:pr-100 mms:pl-50 py-5 lg:py-6-2 lg:pl-20 lg:pr-1-33 faq-panel__content text-white font-worksans-normal text-p4 lg:text-p2" dangerouslySetInnerHTML={{ __html: answer }} />
                </div>
            </div>
        </>
    )
}


export default AccordianStructure;