import React, { useState, useEffect } from "react"

//components
import AccordianStructure from "./accordion-structure"

const FAQInside = (props) => {
    const allFAQs = props.edges
    const [list, setList] = useState([...allFAQs])
    const [activeTab, setActiveTab] = useState(-1)
  
    const onActivateTabChange = (event, index) => {
      setActiveTab(activeTab === index ? -1 : index)
    }

  
    return (
      <>
        {allFAQs.map(({ node: panel }, index) => (
          <AccordianStructure
            key={index}
            faqIndex={index}
            activeTab={activeTab}
            index={panel.id}
            answer={panel.html}
            question={panel.frontmatter.title}
            activateTab={(event) => onActivateTabChange(event, panel.id)}
          />
        ))}
      </>
    )
  }

  export default FAQInside;