import React from "react"

const DownArrowSVG = () => {
    return (
        <svg id="Layer_1" className="faq-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.72 9.57">
            <title>Rectangle 59</title><polyline className="cls-d-arrow-1" points="0.35 0.35 8.87 8.87 17.37 0.36" />
        </svg>
    )
}

export default DownArrowSVG