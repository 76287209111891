import React from "react"
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import createReactClass from 'create-react-class'

//components
import Layout from "../components/layout";
import Seo from "../components/seo";
import LiveChat from "../components/Home/livechat";
import FAQInside from "../components/Accordion/faq-inside"


const Tabs = createReactClass({
  getInitialState() {
    return {
      selected: this.props.selected || 0
    }
  },
  render() {
    return (<div>
      <div className="w-full overlow-hidden">
        <ul className="w-full whitespace-nowrap overflow-auto space-x-2 flex  faqs-list-container md:ml-2 mb-5">
          {this.props.children.map((elem, index) => {
            let style = index == this.state.selected ? 'selected' : '';
            return <li className={`${style}  inline-block  px-3 sm:px-6-6 py-3  text-cyan font-poppings-bold text-p4 lg:text-p2`} key={index} onClick={this.handleChange.bind(this, index)}>{elem.props.title}</li>
          })}
        </ul>
      </div>

      <div className="tab">{this.props.children[this.state.selected]}</div>
    </div>
    )
  },
  handleChange(index) {
    this.setState({ selected: index })
  }
})

const Panel = createReactClass({
  render() {
    return <div>{this.props.children}</div>
  }
})


const FAQ = (props) => {
  const { group: faqsData } = props.data.faqs;
  const FAQpageData = props.data.FAQpageData;
  const SEOdata = props.data.awardpageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.awardpageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.awardpageData.edges[0].node.frontmatter?.Hreflang
  return (
    <Layout>
      <Seo
        title={SEOdata ? SEOdata.seo_title : "faq"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={props.location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""} />

      {/* banner section */}
      <section className="mt-60 md:mt-67 lg:mt-20 lg:pb-54 overflow-hidden">
        <BgImage image={getImage(FAQpageData.frontmatter.banner.image)} className="faq-banner" alt={`${FAQpageData.frontmatter.banner.alt} | DecodeUp FAQs banner`}>
          <div className="container mx-auto h-full">
            <div className="ms:px-50 sm:px-100 md:px-84 1md:px-1-15 3md:px-1-87 lg:px-1-50 xl:pl-2-40 xl:pr-2-32 faq-content-main text-center font-poppings-bold flex flex-col items-center justify-center h-full">
              <p className="text-cyan uppercase text-rp4 sm:text-p4">{FAQpageData.frontmatter.title}</p>
              <h2 className="text-white text-rh2 sm:text-h3 lg:text-h2">{FAQpageData.frontmatter.introduction}</h2>
            </div>
          </div>
        </BgImage>
      </section>

      {/* question and answer section */}

      <section className="pt-39 xl:pt-78 pb-90 xl:pb-1-19">
        <div className="container mx-auto">
          <Tabs selected={0}>
            {faqsData &&
              faqsData.map(({ fieldValue, edges }, index) => (
                <Panel key={index} title={fieldValue}> <FAQInside edges={edges} /></Panel>
              ))}
          </Tabs>
        </div>
      </section>

      {/* live chat section */}
      <LiveChat />

    </Layout>
  )
}

export default FAQ;

export const query = graphql`
query {
  FAQpageData: markdownRemark(fileAbsolutePath: {regex: "/faq.md/"}) {
    frontmatter {
      title
      introduction
      banner {
        alt
        image {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
        }
      }
      
    }
  }
  faqs: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "faqs"}}}) {
    group(field: frontmatter___faq_category) {
      fieldValue
      edges {
        node {
          frontmatter {
            title
            date
          }
          id
          html
        }
      }
    }
  }
  awardpageData: allMarkdownRemark(
    filter: { fileAbsolutePath: {regex: "/faq.md/"} }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }
}
`;
